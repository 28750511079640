<template>
  <div class="stepCon">
    <el-carousel
      ref="elcarousel"
      height="704px"
      :autoplay="false"
      :interval="0"
      arrow="never"
      :loop="false"
      class="carouselBox"
      indicator-position="outside"
    >
      <el-carousel-item>
        <div class="chioseBackBox">
          <div class="headBox">
            <div class="backBtn" @click="goBack">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6620e60584282.svg"
                alt=""
              />
              <span>{{ $fanyi("回退") }}</span>
            </div>
            <img
              class="promptIcon"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/661e0834cce86.svg"
              alt=""
            />
          </div>
          <div class="chioseConBox">
            <div class="titleBox">
              <div class="chioseTitleH1">
                {{ $fanyi("Rakumart经验") }}
              </div>
              <div class="chioseTitleFont">
                {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
              </div>
            </div>
            <div class="chioseTitleH2">{{ $fanyi("商品的用途是什么?") }}</div>
            <div class="singleChoiceList">
              <mradio
                v-model="formregister.commodity_use"
                :label="$fanyi('个人/私人使用')"
                val="De uso personal / Propia"
              />
              <mradio
                v-model="formregister.commodity_use"
                :label="$fanyi('分销和销售')"
                val="Distribución y venta"
              />
            </div>
            <el-button
              type="primary"
              class="ContinuarBtn"
              :class="{ isdisabled: !formregister.commodity_use }"
              @click="formregister.commodity_use ? next() : ''"
              >{{ $fanyi("继续") }}</el-button
            >
          </div>
        </div>
      </el-carousel-item>

      <el-carousel-item>
        <div class="chioseBackBox">
          <div class="headBox">
            <div class="backBtn" @click="goBack">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6620e60584282.svg"
                alt=""
              />
              <span>{{ $fanyi("回退") }}</span>
            </div>
            <img
              class="promptIcon"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/661e0834cce86.svg"
              alt=""
            />
          </div>
          <div class="chioseConBox">
            <div class="titleBox">
              <div class="chioseTitleH1">
                {{ $fanyi("Rakumart经验") }}
              </div>
              <div class="chioseTitleFont">
                {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
              </div>
            </div>
            <div class="chioseTitleH2">
              {{ $fanyi("什么样的销售你提供吗?") }}
            </div>
            <div class="singleChoiceList">
              <mradio
                v-model="formregister.sales_type"
                :label="$fanyi('批发商')"
                val="Mayorista"
              />
              <mradio
                v-model="formregister.sales_type"
                :label="$fanyi('零售商')"
                val="Minorista"
              />
            </div>
            <el-button
              type="primary"
              :class="{ isdisabled: !formregister.sales_type }"
              class="ContinuarBtn"
              @click="formregister.sales_type ? next() : ''"
              >{{ $fanyi("继续") }}</el-button
            >
          </div>
        </div>
      </el-carousel-item>

      <el-carousel-item>
        <div class="chioseBackBox">
          <div class="headBox">
            <div class="backBtn" @click="goBack">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6620e60584282.svg"
                alt=""
              />
              <span>{{ $fanyi("回退") }}</span>
            </div>
            <img
              class="promptIcon"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/661e0834cce86.svg"
              alt=""
            />
          </div>
          <div class="chioseConBox">
            <div class="titleBox">
              <div class="chioseTitleH1">
                {{ $fanyi("Rakumart经验") }}
              </div>
              <div class="chioseTitleFont">
                {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
              </div>
            </div>
            <div class="chioseTitleH2">
              {{ $fanyi("你的主要销售渠道是什么?") }}
              <span class="chioseTitleH2Font"
                >{{ $fanyi("您可以选择多个选项") }}
              </span>
            </div>
            <div class="singleChoiceList">
              <mcheckBox
                v-model="formregister.market_channel"
                :label="$fanyi('电子商务')"
                val="E-Commerce"
              />
              <mcheckBox
                v-model="formregister.market_channel"
                :label="$fanyi('亚马逊')"
                val="Amazon"
              />
              <mcheckBox
                v-model="formregister.market_channel"
                :label="$fanyi('实体店铺')"
                val="Tienda física"
              />
              <mcheckBox
                v-model="formregister.market_channel"
                :label="$fanyi('其他平台')"
                val="Otras plataformas"
              />
            </div>
            <el-button
              type="primary"
              :class="{ isdisabled: !formregister.market_channel.length }"
              class="ContinuarBtn "
              @click="formregister.market_channel.length ? next() : ''"
              >{{ $fanyi("继续") }}</el-button
            >
          </div>
        </div>
      </el-carousel-item>

      <el-carousel-item>
        <div class="chioseBackBox">
          <div class="headBox">
            <div class="backBtn" @click="goBack">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6620e60584282.svg"
                alt=""
              />
              <span>{{ $fanyi("回退") }}</span>
            </div>
            <img
              class="promptIcon"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/661e0834cce86.svg"
              alt=""
            />
          </div>
          <div class="chioseConBox">
            <div class="titleBox">
              <div class="chioseTitleH1">
                {{ $fanyi("Rakumart经验") }}
              </div>
              <div class="chioseTitleFont">
                {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
              </div>
            </div>
            <div class="chioseTitleH2">
              {{ $fanyi("你每年的进口量是多少?") }}
            </div>
            <div class="singleChoiceList">
              <mradio
                v-model="formregister.import_amount"
                :label="'0 - 10K €'"
                val="0 - 10K €"
              />
              <mradio
                v-model="formregister.import_amount"
                :label="'10K - 50K €'"
                val="10K - 50K €"
              />
              <mradio
                v-model="formregister.import_amount"
                :label="'50K - 200K €'"
                val="50K - 200K €"
              />
              <mradio
                v-model="formregister.import_amount"
                :label="'Más de 200K €'"
                val="Más de 200K €"
              />
            </div>
            <el-button
              type="primary"
              :class="{ isdisabled: !formregister.import_amount }"
              class="ContinuarBtn "
              @click="formregister.import_amount ? next() : ''"
              >{{ $fanyi("继续") }}</el-button
            >
          </div>
        </div>
      </el-carousel-item>

      <el-carousel-item>
        <div class="chioseBackBox">
          <div class="headBox">
            <div class="backBtn" @click="goBack">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6620e60584282.svg"
                alt=""
              />
              <span>{{ $fanyi("回退") }}</span>
            </div>
            <img
              class="promptIcon"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/661e0834cce86.svg"
              alt=""
            />
          </div>
          <div class="chioseConBox">
            <div class="titleBox">
              <div class="chioseTitleH1">
                {{ $fanyi("Rakumart经验") }}
              </div>
              <div class="chioseTitleFont">
                {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
              </div>
            </div>
            <div class="chioseTitleH2">
              {{ $fanyi("你从事进口业务多长时间了？") }}
            </div>
            <div class="singleChoiceList">
              <mradio
                v-model="formregister.import_industry"
                :label="$fanyi('不到一年')"
                val="Menos de un año"
              />
              <mradio
                v-model="formregister.import_industry"
                :label="$fanyi('超过一年')"
                val="Más de un año"
              />
            </div>
            <el-button
              type="primary"
              :class="{ isdisabled: !formregister.import_industry }"
              class="ContinuarBtn "
              @click="formregister.import_industry ? next() : ''"
              >{{ $fanyi("继续") }}</el-button
            >
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="chioseBackBox">
          <div class="headBox">
            <div class="backBtn" @click="goBack">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6620e60584282.svg"
                alt=""
              />
              <span>{{ $fanyi("回退") }}</span>
            </div>
            <img
              class="promptIcon"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/661e0834cce86.svg"
              alt=""
            />
          </div>
          <div class="chioseConBox">
            <div class="titleBox">
              <div class="chioseTitleH1">
                {{ $fanyi("Rakumart经验") }}
              </div>
              <div class="chioseTitleFont">
                {{ $fanyi("这个简短的调查将帮助我们个性化您与我们的体验。") }}
              </div>
            </div>
            <div class="chioseTitleH2">
              {{ $fanyi("你在哪里认识我们的?") }}
            </div>
            <div class="singleChoiceList">
              <mradio
                v-model="formregister.experience"
                label="Tik Tok"
                val="Tik Tok"
              />
              <mradio
                v-model="formregister.experience"
                label="Instagram"
                val="Instagram"
              />
              <mradio
                v-model="formregister.experience"
                label="Facebook"
                val="Facebook"
              />
              <mradio
                v-model="formregister.experience"
                label="YouTube"
                val="YouTube"
              />
              <mradio
                v-model="formregister.experience"
                label="Formación"
                val="Formación"
              />
              <mradio
                v-model="formregister.experience"
                label="Recomendación"
                val="Recomendación"
              />
              <mradio
                v-model="formregister.experience"
                label="Otros"
                val="other"
              />
              <el-input
                v-if="formregister.experience == 'other'"
                v-model="otherTxt"
                :placeholder="$fanyi('你能告诉我们在哪里?')"
                class="otherInput"
              ></el-input>
            </div>
            <el-button
              type="primary"
              :class="{
                isdisabled:
                  !formregister.experience ||
                  (formregister.experience == 'other' && !otherTxt),
              }"
              class="ContinuarBtn "
              @click="
                formregister.experience ||
                (formregister.experience == 'other' && !otherTxt)
                  ? next()
                  : ''
              "
              >{{ $fanyi("继续") }}</el-button
            >
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import mradio from "./register-mradio.vue";
import mcheckBox from "./register-mcheckBox.vue";
export default {
  props: ["nextStep", "value"],
  model: {
    prop: "value",
    event: ["upData", "lastStep"],
  },
  data() {
    return {
      formregister: this.value,
      step: 1,
      otherTxt: "",
    };
  },
  components: {
    mcheckBox,
    mradio,
  },
  computed: {},
  created() {
    if (this.$refs.elcarousel) {
      this.$refs.elcarousel.setActiveItem(0);
    }
  },
  methods: {
    goBack() {
      if (this.step == 1) {
        this.$emit("lastStep");
      } else {
        this.step--;
        this.$refs.elcarousel.setActiveItem(this.step - 1);
      }
    },
    setValue() {
      this.$emit("upData", this.formregister);
    },
    next() {
      if (this.step == 2) {
        this.otherTxt = "";
      }
      if (this.step != 6) {
        this.step++;
        this.$refs.elcarousel.setActiveItem(this.step - 1);
      } else {
        if (this.formregister.experience == "other") {
          this.formregister.experience = this.otherTxt;
        }
        this.setValue();
        this.nextStep();
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.stepCon {
  background-color: white;
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 1px;
  position: relative;
  .backBtn {
    position: sticky;
    left: 10px;
  }
  .carouselBox {
    margin-bottom: 40px;
  }
  .headBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 850px;
    margin: 0 auto;
    margin-bottom: 32px;
    .backBtn {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .promptIcon {
      width: 56px;
      height: 56px;
    }
  }

  .chioseConBox {
    display: flex;
    width: 360px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .titleBox {
      text-align: center;
      .chioseTitleH1 {
        font-size: 30px;
        line-height: 1;
        font-weight: 700;
        margin-bottom: 12px;
        color: #101828;
      }
      .chioseTitleFont {
        text-align: center;
        font-size: 16px;
        line-height: 24px; /* 150% */
        margin-bottom: 16px;
        color: var(--Gray-600, #475467);
      }
    }
    .chioseTitleH2 {
      width: 100%;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      .chioseTitleH2Font {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .singleChoiceList {
      width: 100%;
      margin-bottom: 24px;
      .singleChoiceItem {
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--Base-White, #fff);
        padding: 10px 14px;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
        .itemIcon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid var(--Gray-300, #d0d5dd);
          margin-right: 8px;
        }
        .itemIcon.ischecked {
          border: solid 5px #1767d0;
        }
        span {
          color: var(--Gray-700, #344054);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .otherInput {
        border-radius: 8px;
        background: var(--Base-White, #fff);
        /deep/.el-input__inner {
          border: 1px solid var(--Gray-700, #344054);
          color: var(--Gray-700, #344054);
          border-radius: 8px;
          font-weight: 600;
        }
      }
    }
    .ContinuarBtn {
      border-radius: 8px;
      border: 1px solid var(--Rakumart-500, #1a73e8);
      background: var(--Rakumart-500, #1a73e8);
      width: 100%;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 32px;
    }
    .ContinuarBtn.isdisabled {
      border: 1px solid var(--Gray-200, #eaecf0);
      background: var(--Gray-100, #f2f4f7);
      color: var(--Gray-400, #98a2b3);
    }
  }
}
/deep/.el-carousel__indicators {
  pointer-events: none;
  .el-carousel__indicator {
    .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 6px;
      background: #eaecf0;
      opacity: 1;
    }
  }
  .is-active {
    .el-carousel__button {
      background: #1355ab;
    }
  }
}
</style>
<style></style>
