<!-- 注册页-验证验证码 -->
<template>
  <div class="verificationCodeModel">
    <el-carousel
      ref="elcarousel"
      height="520px"
      :autoplay="false"
      :interval="0"
      arrow="never"
      :loop="false"
      class="carouselBox"
      indicator-position="outside"
    >
      <el-carousel-item>
        <div class="headBox">
          <div class="backBtn" @click="goBack">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6620e60584282.svg"
              alt=""
            />
            <span>{{ $fanyi("回退") }}</span>
          </div>
          <img
            class="promptIcon"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202405/663d775292898.svg"
            alt=""
          />
        </div>
        <div class="modelCon">
          <h1>{{ $fanyi("验证帐户") }}</h1>
          <h2>
            {{ $fanyi("请输入我们发送给您的验证码:") }} <b>{{ email }}</b>
          </h2>
          <div class="verCodeInputBox">
            <div class="inputList">
              <input
                ref="inputBoxRef"
                type="text"
                @input="inputSet(i - 1)"
                v-model="verCode[i - 1]"
                v-for="i in 6"
                @paste="inputpaste($event, i - 1)"
                :key="i"
                :class="{
                  primary: inputType == 'primary',
                  error: inputType == 'error',
                }"
                class="inputCss"
                @keydown="BackspaceMethods(i - 1)"
              />
            </div>
            <div class="inputList" v-if="bendi">
              <input
                ref="ssssss"
                type="text"
                v-model="verCode[i - 1]"
                v-for="i in 11"
                @input="bendiInp(i - 1)"
                @paste="inputpaste($event, i - 1)"
                :key="i"
                :class="{
                  primary: inputType == 'primary',
                  error: inputType == 'error',
                }"
                class="inputCss"
                @keydown="BackspaceMethods(i - 1)"
              />
            </div>
            <div class="errorTipBox" v-if="inputType == 'error'">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202405/663d8a20358d5.svg"
                alt=""
              />
              <span>
                {{
                  $fanyi("您输入的验证码不正确。请检查代码或点击接收另一个。")
                }}
              </span>
            </div>
          </div>

          <el-button
            type="primary"
            :class="{ isdisabled: !verCodeInputStatus }"
            class="ContinuarBtn "
            @click="verCodeMe"
            >{{ $fanyi("检查我的验证码") }}</el-button
          >
          <div class="tipBox">
            ¿No has recibido ningún correo electrónico? <br />
            <b
              :class="{ isdisabled: $parent.Countdown != 60 }"
              @click="$parent.Countdown == 60 ? $parent.openODialog() : ''"
            >
              Pulsa aquí
              {{ $parent.Countdown != 60 ? `(${$parent.Countdown}s)` : "" }}
            </b>
            para que te lo enviemos de nuevo
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="headBox">
          <div class="backBtn" @click="goBack">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6620e60584282.svg"
              alt=""
            />
            <span>{{ $fanyi("回退") }}</span>
          </div>
          <img
            class="promptIcon"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202405/663db56e9915f.svg"
            alt=""
          />
        </div>
        <div class="modelCon">
          <h1>
            ¡Tu cuenta ha sido <br />
            verificada!
          </h1>
          <h2 style="margin-bottom: 20px;">
            Tu cuenta ha sido verificada con éxito.
          </h2>
          <h2>
            Por favor, continua con el proceso para finalizar tu registro en
            Rakumart.
          </h2>

          <el-button
            type="primary"
            :class="{ isdisabled: !verCodeInputStatus }"
            class="ContinuarBtn "
            @click="verCodeMe"
            >Continuar</el-button
          >
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  props: ["email"],
  model: {
    event: ["lastStep", "nextStep"],
  },
  data() {
    return {
      verCode: [],
      inputType: "normal", //normal/error/primary
      step: 1,
      bendi:
        location.host.indexOf("8080") != -1 ||
        location.host.indexOf("8081") != -1 ||
        location.host.indexOf("8082") != -1,
    };
  },
  components: {},
  computed: {
    verCodeInputStatus() {
      let num = 0;
      for (let i in this.verCode) {
        if (!!this.verCode[i]) {
          num++;
        }
      }
      if (num >= 6) {
        this.inputType = "primary";
      } else {
        this.inputType = "normal";
      }
      return num >= 6;
    },
  },
  created() {},
  methods: {
    goBack() {
      if (this.step == 1) {
        this.$emit("lastStep");
      } else {
        this.step--;
        this.$refs.elcarousel.setActiveItem(this.step - 1);
      }
    },

    // 输入限制数字和后移光标
    inputSet(i) {
      let num = this.verCode[i];
      num = num.replace(/[^\d]/g, "");
      if (num) {
        num = num.match(/\d$/)[0];
      } // 使用正则表达式匹配最后一个数字
      this.verCode[i] = num;
      if (i < 5 && num) {
        this.$refs.inputBoxRef[i + 1].focus();
      } else {
      }
    },

    bendiInp(i) {
      if (i < 10) {
        this.$refs.ssssss[i + 1].focus();
      } else {
      }
    },

    // 删除前移光标
    BackspaceMethods(i) {
      if (event.key === "Backspace") {
        if (!this.verCode[i] && i != 0) {
          this.verCode[i - 1] = "";
          this.$refs.inputBoxRef[i - 1].focus();
        }
      }
    },

    // 粘贴填充输入框
    inputpaste(event, i) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      // 处理粘贴的内容
      //   console.log("粘贴的内容:", pastedText);
      if (pastedText.length == 1) {
        this.verCode[i] = pastedText;
        this.$forceUpdate();
      } else {
        this.verCode = [];
        let count = 0;
        for (let i = 0; i < pastedText.length && count < 6; i++) {
          if (!isNaN(parseInt(pastedText[i]))) {
            this.verCode[count] = pastedText[i];
            count++;
          }
        }
        this.$forceUpdate();
      }
    },

    // 校验验证码
    verCodeMe() {
      let verCodeStr = this.verCode.join("");
      if (this.step != 2) {
        if (!this.verCodeInputStatus) {
          return false;
        }
        // 假如手机号和验证码不为空,就根据手机号和验证码请求接口校验

        this.$api
          .checkVerificationCode({
            occasion: "register",
            number: this.$parent.formregister[this.$parent.formregister.tools],
            code: verCodeStr,
          })
          .then((res) => {
            if (res.success == false) {
              this.$message.error(this.$fanyi(res.msg));
              this.inputType = "error";
            } else {
              this.step++;
              this.$refs.elcarousel.setActiveItem(1);
            }
          });
      } else {
        this.$emit("nextStep", verCodeStr);
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.verificationCodeModel {
  background-color: white;
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 132px;
  .backBtn {
    position: sticky;
    left: 10px;
  }
  .carouselBox {
    margin-bottom: 40px;
  }
  .headBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 850px;
    margin: 0 auto;
    margin-bottom: 32px;
    .backBtn {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .promptIcon {
      width: 56px;
      height: 56px;
    }
  }
  .modelCon {
    margin: 0 auto;
    width: 380px;
    h1 {
      color: #101828;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px; /* 126.667% */
      margin-bottom: 32px;
    }
    h2 {
      color: #475467;
      /* Text md/Semibold */
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
      text-align: center;
      margin-bottom: 32px;
      b {
        font-weight: 600;
      }
    }
    .verCodeInputBox {
      margin: 0 auto 32px;
      .inputList {
        display: flex;
        justify-content: center;
        .inputCss {
          margin: 0 4px;
          border-radius: 8px;
          border: 1px solid #d0d5dd;
          background: #fff;
          /* Shadows/shadow-xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          display: flex;
          width: 64px;
          min-height: 64px;
          padding: 2px 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #d0d5dd;
          text-align: center;
          /* Display lg/Semibold */
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: 60px; /* 125% */
          letter-spacing: -0.96px;
        }
        .inputCss.primary {
          border-color: #77b2ff;
          color: #1767d0;
        }
        .inputCss.error {
          border-color: #fda29b;
          color: #d92d20;
        }
      }

      .errorTipBox {
        display: flex;
        align-items: center;
        margin-top: 8px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          color: var(--Error-600, #d92d20);
          /* Text xs/Semibold */
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }

    .ContinuarBtn {
      height: 44px;
      border-radius: 8px;
      border: 1px solid var(--Rakumart-500, #1a73e8);
      background: var(--Rakumart-500, #1a73e8);
      width: 100%;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 32px;
    }
    .ContinuarBtn.isdisabled {
      border: 1px solid var(--Gray-200, #eaecf0);
      background: var(--Gray-100, #f2f4f7);
      color: var(--Gray-400, #98a2b3);
    }
    .tipBox {
      color: var(--Gray-600, #475467);
      text-align: center;

      /* Text xs/Regular */
      font-family: "Open Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      b {
        color: #1a73e8;
        font-weight: 600;
        cursor: pointer;
      }
      b.isdisabled {
        color: #7c7c7c;
      }
    }
  }
}
/deep/.el-carousel__indicators {
  pointer-events: none;
  .el-carousel__indicator {
    .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 6px;
      background: #eaecf0;
      opacity: 1;
    }
  }
  .is-active {
    .el-carousel__button {
      background: #1355ab;
    }
  }
}
</style>
